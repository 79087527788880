import React, { useState, useEffect } from "react";
import { Link } from 'gatsby';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../components/header';
import 'bootstrap/dist/css/bootstrap.min.css';

const About = () => {
  const [duration, updateDuration] = useState('');
  const [ftp, updateftp] = useState('');
  const [np, updateNP] = useState('');

  const calcualte = ()=>{
    
    const tssCalc = Math.round(((60 * duration * np * (np / ftp)) / (ftp * 3600)) * 100);
    document.getElementById('calculatedValue').innerHTML = tssCalc;  
    
  }

  useEffect(() => {
    calcualte()

  });

  return (
    <>
      <Header />
      <Container>
        <Form>
          <Row xs={2} md={2} lg={2} className="mt-4">
            <Col>
              <Form.Label>Normalized Power</Form.Label>
              <Form.Control
                placeholder="np"
                id="np"
                value={np}
                onChange={e => { updateNP(e.target.value)}}
              />
            </Col>
            <Col>
              <Form.Label>FTP</Form.Label>
              <Form.Control
                placeholder="FTP"
                id="ftp"
                value={ftp}
                onChange={e => updateftp(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label>Duration in Min</Form.Label>
              <Form.Control
                id="duration"
                value={duration}
                placeholder="DUR"
                onChange={e => updateDuration(e.target.value)}
              />
            </Col>
            <Col>
              <h1
                className="mt-4 tsscalc"
                id="calculatedValue"
              ></h1>
            </Col>
          </Row>
        </Form>
        <Link to="/">&larr; back to home</Link>
      </Container>
    </>
  );
};

export default About;